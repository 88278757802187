<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-10 mx-auto">
        <div class="card rounded shadow-lg text-left">
          <div class="card-body">
            <div class="card-title text-center">
                <h2><b>Términos y condiciones</b></h2>
            </div>
            <p>Las presentes condiciones de uso de la página web, regulan los términos de acceso y uso del <b>Sistema de Gestión Documental Siged</b>, propiedad de Archivos y Sistemas AYS y correo electrónico <i>contacto@archivosysistemas.com</i>, en adelante, “la Empresa”, que el usuario del portal deberá aceptar para usar todos los servicios e información que se facilitan desde el portal.</p>
            <p>El usuario así como la Empresa, propietaria del portal, podrán ser denominados conjuntamente como las partes. El mero acceso o utilización del portal, de todos o parte de sus contenidos y servicios significa la plena aceptación de las presentes condiciones de uso. La puesta a disposición y el uso del portal se entiende sometida al estricto cumplimiento de los términos recogidos en las presentes condiciones de uso del portal.</p>
            <p><b>Regulación de las condiciones de uso</b></p>
            <p>Las presentes condiciones generales de uso del portal regulan el acceso y la utilización del portal, incluyendo los contenidos y los servicios puestos a disposición de los usuarios en y/o a través del portal, bien por el portal, bien por sus usuarios o por cualquier tercero. No obstante, el acceso y la utilización de ciertos contenidos y/o servicios pueden encontrarse sometidos a determinadas condiciones específicas.</p>
            <p><b>Modificaciones</b></p>
            <p>La empresa se reserva la facultad de modificar en cualquier momento las condiciones generales de uso del portal. En todo caso, se recomienda que consulte periódicamente las presentes condiciones generales de uso del portal, ya que pueden ser modificadas.</p>
            <p><b>Contenido del portal</b></p>
            <p><b>Información y servicios</b></p>
            <p>Los usuarios pueden acceder a través del portal a diferente tipo de información y servicios. El portal se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración de la información y servicios ofrecidos desde el portal. El usuario reconoce y acepta expresamente que en cualquier momento el portal pueda interrumpir, desactivar y/o cancelar cualquier información o servicio. El portal realizará sus mejores esfuerzos para intentar garantizar la disponibilidad y accesibilidad a la web. No obstante, en ocasiones, por razones de mantenimiento, actualización, cambio de ubicación, etc., podrá suponer la interrupción del acceso al portal.</p>
            <p><b>Disponibilidad de la información y servicios del Portal</b></p>
            <p>El portal no garantiza la disponibilidad continua y permanente de los servicios, quedando de este modo exonerado de cualquier responsabilidad por posibles daños y perjuicios causados como consecuencia de la falta de disponibilidad del servicio por motivos de fuerza mayor o errores en las redes telemáticas de transferencia de datos, ajenos a su voluntad, o por desconexiones realizadas por trabajos de mejora o mantenimiento de los equipos y sistemas informáticos. En estos casos, el portal hará sus mejores esfuerzos para avisar con una antelación de 24 horas a la interrupción. El portal no será responsable por la interrupción, suspensión o finalización de la información o servicios. Así mismo, no se responsabiliza de las posibles omisiones, pérdidas de información, datos, configuraciones, accesos indebidos o vulneración de la confidencialidad que tengan origen en problemas técnicos, de comunicaciones u omisiones humanas, causadas por terceros o no imputables al portal. Tampoco responderá de los daños producidos por ataques informáticos o causados por virus que afecten a programas informáticos, sistemas de comunicaciones o equipos utilizados por el Portal pero fabricados o facilitados por un tercero. El portal podrá, a su sola discreción, denegar, retirar, suspender y/o bloquear en cualquier momento y sin necesidad de previo aviso el acceso a la información y servicios a aquellos usuarios que incumplan las presentes normas.</p>
            <p><b>Responsabilidad del Portal sobre los contenidos</b><p>
            <p>Opc A. El portal controlará la licitud de aquellos contenidos o servicios prestados a través de la plataforma por terceras partes. En caso de que el usuario como consecuencia de la utilización del portal sufra algún daño o perjuicio podrá comunicarlo y se tomarán las medidas que se estimen oportunas para solventarlo.</p>
            <p>Opc B. El portal no interviene en la creación de aquellos contenidos y/o servicios prestados o suministrados por terceras partes en y/o a través de la aplicación, del mismo modo que tampoco controla su licitud. En cualquier caso, no ofrece ninguna clase de garantía sobre los mismos. El usuario reconoce que el portal no es ni será responsable de los contenidos y/o servicios prestados o suministrados por terceras partes en y/o a través del portal. El usuario acepta que la web no asumirá responsabilidad alguna por cualquier daño o perjuicio producido como consecuencia de la utilización de esta información o servicios de terceros.</p>
            <p>Exceptuando los casos que la Ley imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga, la aplicación no garantiza ni asume responsabilidad alguna respecto a los posibles daños y perjuicios causados por el uso y utilización de la información, datos y servicios del portal.</p>
            <p>En todo caso, el portal excluye cualquier responsabilidad por los daños y perjuicios que puedan deberse a la información y/o servicios prestados o suministrados por terceros diferentes de la Empresa. Toda la responsabilidad será del tercero ya sea proveedor, colaborador u otro.</p>
            <p><b>Obligaciones del Usuario</b></p>
            <p>El usuario deberá respetar en todo momento los términos y condiciones establecidos en el presente aviso legal. De forma expresa el usuario manifiesta que utilizará el portal de forma diligente y asumiendo cualquier responsabilidad que pudiera derivarse del incumplimiento de las normas.</p>
            <p>El usuario se obliga, en aquellos casos que se le soliciten datos o información, a no falsear su identidad haciéndose pasar por cualquier otra persona. El usuario acepta que la utilización del Portal será efectuada con fines estrictamente personales, privados y particulares. El usuario no podrá utilizar el portal para actividades contrarias a la Ley, la moral y el orden público así como para finalidades prohibidas o que vulneren o lesionen derechos de terceros. Asimismo, queda prohibida la difusión, almacenamiento y/o gestión de datos o contenidos que infrinjan derechos de terceros o cualesquiera normativas reguladoras de derechos de propiedad intelectual o industrial.</p>
            <p>Así mismo, el usuario no podrá utilizar el portal para transmitir, almacenar, divulgar promover o distribuir datos o contenidos que sean portadores de virus o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o perjudicar el funcionamiento de cualquier programa o equipo informático o de telecomunicaciones.</p>
            <p>El usuario se obliga a indemnizar y a mantener indemnes al portal por cualquier daño, perjuicio, sanción, multa, pena o indemnización que tenga que hacer frente el portal.</p>
            <div class="text-center">
                <button class="btn btn-primary" v-on:click="aceptar()">Aceptar</button>
            </div>
          </div>
        </div>        
    </div>
  </section>
</template>
<script>
export default {
 
 methods: {
   aceptar(){
     if (this.$route.params.close){
       window.close();
     } else {
       this.$router.back();
     }
   }
 }
}
</script>